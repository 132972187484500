.all_transaction_mobile_footer_added_booking {
  background-color: #e5fff7 !important;
}
.all_transaction_mobile_footer_processed {
  background-color: #e5fff7 !important;
}
.all_transaction_mobile_footer_failed {
  background-color: #fbebea !important;
}
.all_transaction_mobile_footer_pending {
  background-color: #fff7e5 !important;
}
.all_transaction_mobile_footer_refunded {
  background-color: #e5fff7 !important;
}
