.root {
  display: flex;
  align-items: center;
  gap: 4px;
}

.root svg {
  color: #493ab1;
  font-size: 16px;
}

.root button {
  padding: 0px;
}

.linkWrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 172px;
  font-size: 14px;
  line-height: 17px;
}

.linkWrapper a {
  color: #0052cc;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .linkWrapper a {
    text-decoration: unset;
  }
}
