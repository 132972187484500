.root {
  border-radius: 10px;
  border: 1px solid #eceeef;
  height: 234px;
  display: grid;
  place-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.1s all;
  user-select: none;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 18.64px;
  text-align: center;
  align-items: center;
  padding: 0px 44px;
}

.icon {
  width: 60px;
  height: 60px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.description {
  color: #272522;
  font-size: 12px;
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0.24px;
}

.root:hover {
  border: 1px solid #493ab1;
}

.active {
  border: 1px solid #493ab1;
  background: #f8f7fd;
}

.wrapper {
  flex: 1;
  border: 2px solid transparent;
  border-radius: 12px;
}

.wrapper:hover {
  border-color: #493ab1;
  background: #f8f7fd;
}

.wrapperActive {
  border-color: #493ab1;
}

@media screen and (max-width: 768px) {
  .root {
    height: 100%;
  }

  .icon {
    width: 46px;
    height: 46px;
  }

  .content {
    padding: 16px 8px;
    gap: 8px;
  }

  .title {
    font-size: 14px;
    margin-top: 8px;
  }
}
