.container {
  background: linear-gradient(
    to right,
    rgba(242, 242, 255, 1),
    rgba(229, 241, 255, 1)
  );
}

.title {
  font-size: 16px;
  font-weight: 500;
  background: linear-gradient(
    to right,
    rgba(73, 58, 177, 0.85),
    rgba(0, 82, 204, 0.85)
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.tooltip {
  fill: rgba(73, 58, 177, 1) !important;
  margin-bottom: 4px;
}
