.checkbox {
  fill: rgba(73, 58, 177, 1) !important;
}

.checkbox_label {
  font-size: 12px;
  font-weight: 400;
  color: rgba(39, 37, 34, 0.7);
}

.labelField {
  font-size: 16px;
  font-weight: 500;
}

.infoContainer {
  background-color: rgba(221, 251, 255, 1);
}

.infoText {
  font-size: 12px;
  font-weight: 400;
}

.infoIcon {
  font-size: 14px !important;
}
