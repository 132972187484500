.default_type_container {
  color: white;
  background-color: rgba(181, 181, 181, 1);
  padding: 3px 6px;
  border-radius: 5px;
}

.titleText {
  font-weight: 500;
  font-size: 16px;
}

.actionContainer {
  background-color: rgba(238, 236, 249, 1);
}

.deleteIcon {
  fill: rgba(191, 38, 0, 0.85) !important;
  font-size: 22px !important;
  cursor: pointer;
}

.editIcon {
  fill: rgba(73, 58, 177, 1) !important;
  font-size: 22px !important;
  cursor: pointer;
}
