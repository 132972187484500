.actionCta {
  justify-content: flex-start !important;
  text-transform: none !important;
  padding: 12px 20px !important;
}

.action {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.09px;
  text-decoration: underline;
  color: #493ab1;
  display: flex;
  align-items: center;
}

.action svg {
  color: #493ab1;
}

@media screen and (max-width: 768px) {
  .chipClassName {
    margin-right: 8.3px !important;
  }
}
